<template>
  <div class="wallet-methods">
    <div class="wallet-methods__header">
      <wallet-tabs v-if="props.showTabs" :selected="props.selectedTab" @change-tab="emit('changeTab', $event)" />

      <div v-else class="wallet-methods__header-title">
        {{ props.modalTitle }}
      </div>
    </div>

    <balance :withdraw="props.selectedTab === 'withdraw'">
      <template v-if="props.selectedTab === 'deposit'">
        <div v-if="showDepositGuide" class="wallet-methods__guide">
          <div class="wallet-methods__guide-header" :class="{ 'is-open': guideOpen }" @click="toggleGuide">
            <div class="wallet-methods__guide-title">
              {{ getContent(walletContent, defaultLocaleWalletContent, 'deposit.guide.title') }}
            </div>

            <atomic-icon id="arrow-expand-less" class="wallet-methods__guide-toggle" />
          </div>

          <ul ref="guideContentElement" class="wallet-methods__guide-content" :style="guideStyles">
            <li v-for="(step, index) in guideSteps" :key="index">{{ step.text }}</li>
          </ul>
        </div>

        <form-input-payments
          v-model:active-method="currentDepositMethod"
          :items="depositMethods"
          @update:active-method="handleMethodChanged('deposit')"
          @method-click="emit('methodClick')"
        />
      </template>

      <form-input-payments
        v-if="props.selectedTab === 'withdraw'"
        v-model:active-method="currentWithdrawMethod"
        :items="withdrawMethods"
        @update:active-method="handleMethodChanged('withdraw')"
        @method-click="emit('methodClick')"
      />

      <div v-if="showNotAvailableText" class="wallet-modal__empty-methods">
        <atomic-icon id="info" />

        <span>
          {{ getContent(walletContent, defaultLocaleWalletContent, 'notAvailableText') }}
        </span>
      </div>
    </balance>

    <wallet-dots :items-count="2" :active-index="0" />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { CIWalletModal, IPaymentMethod } from '~/types';

  const props = defineProps<{
    showTabs: boolean;
    selectedTab: string;
    modalTitle: string;
  }>();

  const currentDepositMethod = defineModel<IPaymentMethod>('currentDepositMethod');
  const currentWithdrawMethod = defineModel<IPaymentMethod>('currentWithdrawMethod');
  const walletContent: Maybe<CIWalletModal> = inject('walletContent');
  const defaultLocaleWalletContent: Maybe<CIWalletModal> = inject('defaultLocaleWalletContent');

  const emit = defineEmits(['changeTab', 'methodClick']);
  const { getContent } = useProjectMethods();
  const walletStore = useWalletStore();
  const { depositMethods, withdrawMethods, successDepositCount } = storeToRefs(walletStore);

  const showNotAvailableText = computed(() => {
    return (
      (!depositMethods.value?.length && props.selectedTab === 'deposit') ||
      (!withdrawMethods.value?.length && props.selectedTab === 'withdraw')
    );
  });

  const handleMethodChanged = (walletOperationType: 'deposit' | 'withdraw'): void => {
    useEvent('analyticsEvent', {
      event: 'walletChangeMethod',
      walletOperationType,
    });
  };

  const guideOpen = ref<boolean>(false);
  const maxHeight = ref<number>(0);
  const guideSteps = computed(() => getContent(walletContent, defaultLocaleWalletContent, 'deposit.guide.steps') || []);

  const guideStyles = computed(() => ({ '--max-height': guideOpen.value ? `${maxHeight.value}px` : '0px' }));
  const guideContentElement = ref<HTMLElement>();

  const toggleGuide = (): void => {
    guideOpen.value = !guideOpen.value;
    maxHeight.value = guideOpen.value ? guideContentElement.value?.scrollHeight || 0 : 0;
  };

  const showDepositGuide = computed(() => {
    const showGuide = walletContent?.deposit?.guide?.showGuide;
    const showWithoutDepositOnly = walletContent?.deposit?.guide?.showWithoutDepositOnly;

    if (showWithoutDepositOnly) return showGuide && guideSteps.value.length && successDepositCount.value === 0;
    return showGuide && guideSteps.value.length;
  });

  onMounted(() => {
    if (guideContentElement.value) {
      maxHeight.value = guideContentElement.value?.scrollHeight || 0;
    }
  });
</script>

<style src="~/assets/styles/components/wallet/methods.scss" lang="scss" />
